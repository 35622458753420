import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import teamwork from "../images/teamwork.jpeg"
import Img from "gatsby-image"

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query {
      planner: file(relativePath: { eq: "planner.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="teamontwikkeling met duurzaam resultaat"
        description="Als organisatie kun je de Zipperr® methode toepassen met de ondersteuning van een professional op het gebied van teamontwikkeling en persoonlijke groei."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${teamwork})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Waarom is de Zipperr® Methode een duurzaam traject voor
              teamontwikkeling?
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Structuur</p>
        </div>
        <p>
          Creëer de optimale werkomgeving met de gestructureerde aanpak van de
          Zipperr® Methode en laat je begeleiden middels een duurzaam en
          effectief Zipperr®abonnement. In overleg met de werkgever coachen wij
          functioneel op locatie of op ons kantoor in Nuenen. Naar behoefte een
          dag per week, eens per 2 weken of per maand. Werkgevers of werknemers
          kunnen op die dag een afspraak boeken voor een consult. Zo kunnen wij
          op tijd problemen of aandachtspunten signaleren. Met een
          jaarabonnement is je bedrijf constant verzekerd van ondersteuning voor
          jou en je medewerkers.
        </p>
      </div>

      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>
            Wat is een duurzaam en effectief traject voor teamontwikkeling?
          </h2>
          <p>
            Als organisatie of particulier kun je de Zipperr® methode toepassen
            met de ondersteuning van een professional op het gebied van
            teamontwikkeling, mental coaching, organisatieadvies,
            loopbaanbegeleiding, stressconsultancy, re-integratie, mediation,
            verzuimbegeleiding, breintraining en veranderbegeleiding. In de
            afgelopen jaren heeft Zipperr®een bijdrage geleverd aan de
            persoonlijke ontwikkeling van topsporters, professionals,
            managementteams, leidinggevenden en werknemers om beter te worden in
            de breedst mogelijk zin van het woord!
          </p>
          <p className={"btn-container"}>
            <Link
              to="/traject"
              className="btn primary-lg round expand-on-hover"
            >
              Meer informatie
            </Link>
          </p>
        </div>
        <div className={"half block imger"}>
          <div className={"imageStackedBox"}>
            <Img
              fluid={data.planner.childImageSharp.fluid}
              objectFit="cover"
              // objectPosition="50% 50%"
              alt="Zipper Case"
              style={{ maxHeight: "45%" }}
            />
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default SecondPage
